<template>
    <div class="page">
        <el-form size="small" ref="searchForm" :model="searchForm"  class="searchForm query-form" label-width="100px">
            <el-form-item prop="propertyOwner" label="产权人：">
                <el-select v-model="searchForm.propertyOwner" filterable placeholder="请选择" clearable>
                    <el-option
                            :key="index"
                            :label="item"
                            :value="item"
                            v-for="(item, index) in propertyOwnerOpt">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="originalAuthor" label="原创作者：">
                <el-select v-model="searchForm.originalAuthor" filterable placeholder="请选择" clearable>
                    <el-option
                            :key="index"
                            :label="item"
                            :value="item"
                            v-for="(item, index) in originalAuthorOpt">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="isShare" label="是否分享：">
                <el-select v-model="searchForm.isShare" placeholder="请选择" clearable>
                    <el-option label="启用" value="0"></el-option>
                    <el-option label="禁用" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="downloadRestriction" label="下载限制：">
                <el-select v-model="searchForm.downloadRestriction" placeholder="请选择" clearable>
                    <el-option label="仅本人下载" value="0"></el-option>
                    <el-option label="所有人下载" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="startTime" label="成文时间：" class="timeBox">
                <el-date-picker v-model="dateTime" value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                type="daterange" clearable start-placeholder="开始时间"
                                end-placeholder="结束时间" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="keyWord" label="输入搜索：">
                <el-input v-model.trim="searchForm.keyWord" @keyup.enter.native="search(1)" maxlength="50" placeholder="请输入关键字（50字内）" clearable></el-input>
            </el-form-item>
            <el-form-item label-width="20px">
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <div class="f_r">
                <el-button icon="el-icon-plus" size="small" type="primary" v-if="hasPermission('intellectual:list:add')" @click="addEdit(null, 'add')">新增</el-button>
                <el-button icon="el-icon-delete" type="danger" size="small" v-if="hasPermission('intellectual:list:del')"
                           @click="del()">批量删除
                </el-button>
            </div>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 347px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="multipleTable" row-key='id'>
                <el-table-column type="selection" :reserve-selection='true' fixed/>
                <el-table-column prop="resourceUrl" label="资源" width="60">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 50px; height: 50px"
                                :src="scope.row.resourceUrl"
                                fit="contain"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="resourceName" label="资源名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="propertyOwner" label="产权人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="source" label="来源" show-overflow-tooltip></el-table-column>
                <el-table-column prop="writtenTime" label="成文时间" width="130" sortable show-overflow-tooltip></el-table-column>
                <el-table-column prop="propertyIdentification" label="产权标识" show-overflow-tooltip></el-table-column>
                <el-table-column prop="originalSignature" label="原创签名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="originalAuthor" label="原创作者" show-overflow-tooltip></el-table-column>
                <el-table-column prop="originalWatermark" label="原创水印">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.originalWatermark" active-value="0" inactive-value="1" @change="originalWatermarkChange(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="isShare" label="是否分享">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isShare" active-value="0" inactive-value="1" disabled></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="shareCount" label="分享" show-overflow-tooltip></el-table-column>
                <el-table-column prop="rating" label="评级" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("rating_type", scope.row.rating ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="evaluate" label="评价" show-overflow-tooltip></el-table-column>
                <el-table-column prop="downloadRestrictions" label="下载限制" width="120" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.downloadRestrictions == 0 ? '仅本人下载': '所有人下载'}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="220">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-if="hasPermission('intellectual:list:view')"
                                   @click="addEdit(scope.row, 'view')">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('intellectual:list:edit')"
                                   @click="addEdit(scope.row, 'edit')">修改
                        </el-button>
                        <el-button type="text" size="small" v-if="hasPermission('intellectual:list:del')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                        <el-button type="text" size="small" v-if="scope.row.isDownload == 1"
                                   @click="download(scope.row)">下载
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <ListForm ref="listForm" @refreshDataList="search()"></ListForm>
    </div>
</template>

<script>
    import ListForm from './listForm'
    export default {
        components: {ListForm},
        data() {
            return {
                searchForm: {
                    isShare: '',
                    downloadRestriction: '',
                    startTime: '',
                    endTime: '',
                    keyWord: '',
                    originalAuthor: '',
                    propertyOwner: '',
                    current: 1,
                    size: 10,
                },
                propertyOwnerOpt: [],
                originalAuthorOpt: [],
                dateTime: [],
                loading: false,
                dataList: [],
                dataListSelections: [],
                total: 0,
            }
        },
        mounted() {
            this.search()
        },
        methods: {
            // 查询
            search(type) {
                this.getPropertyOwner()
                this.getOriginalAuthor()
                if (type == 1) {
                    this.searchForm.current = 1
                }
                if (type == 0) {
                    this.$refs.multipleTable.clearSelection()
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                p.startTime = this.dateTime.length ? this.dateTime[0] : ''
                p.endTime = this.dateTime.length ? this.dateTime[1] : ''
                this.$axios(this.api.dyyg.dyygintellectualpropertyList, p).then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 获取产权人
            getPropertyOwner() {
                this.$axios(this.api.dyyg.propertyOwnerList).then(data => {
                    if (data.status) {
                        this.propertyOwnerOpt = data.data
                    }
                })
            },
            // 获取原创作者
            getOriginalAuthor() {
                this.$axios(this.api.dyyg.originalAuthorList).then(data => {
                    if (data.status) {
                        this.originalAuthorOpt = data.data
                    }
                })
            },
            // 下载
            download(item) {
                this.$confirm(`确认下载所选数据？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.dyyg.dyygintellectualpropertyDownload, {
                        id: item.id,
                    }, 'get', 'blob').then(res => {
                        let blob = new Blob([res.data], {type: 'image/jpeg'})
                        let link = document.createElement('a')
                        link.download = decodeURI(item.resourceName)
                        link.href = window.URL.createObjectURL(blob)
                        link.click()
                        this.loading = false
                        this.$nextTick(() => {
                            this.$message.success('下载成功')
                        })
                    })
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.listForm.init(row, method)
            },
            // 原创水印
            originalWatermarkChange(row) {
                this.$axios(this.api.dyyg.enableWatermark, {id: row.id, type: row.originalWatermark}, 'get').then(data => {
                    if (data.status) {
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error(data.msg)
                    }
                    this.search()
                })
            },
            // 删除
            del(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.dyyg.dyygintellectualpropertyRemoveById, {id: ids}, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.search(0)
                        } else {
                            this.$message.error('删除失败')
                        }
                    })
                })
            },
            // 重置
            reset() {
                this.$refs.multipleTable.clearSelection()
                this.$refs.searchForm.resetFields();
                this.dateTime = []
                this.search(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        },
    }
</script>

<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 25%;
        }
        .timeBox.el-form-item--small.el-form-item {
            width: 32%;
        }
    }
</style>
